<template>
  <div>
    <portal to="breadcrumb">
      <Breadcrumb>
        <b-breadcrumb-item active>
          Select company
        </b-breadcrumb-item>
      </Breadcrumb>
    </portal>
    <card
      external-card="card material-card card-form-container"
      internal-card="material-card-content"
    >
      <div
        v-if="companiesFromStore.length > 0"
        slot="body"
        style="padding:0px 10px 10px"
      >
        <b-input
          id="search-company"
          v-model="searchInput"
          v-focus
          icon="magnify"
          type="search"
          placeholder="Search company..."
          style="margin-bottom:10px"
          autocomplete="off"
          @input="search"
        />
        <company-card
          v-for="item in filteredCompanies"
          :key="item.id"
          :company="item"
        />
      </div>
      <div
        v-if="$isBgCloud"
        slot="footer"
        class="force-right"
      >
        <router-link
          :to="{ name: 'createcompany'}"
          class="button is-primary"
        >
          Create new company
        </router-link>
      </div>
    </card>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import Fuse from 'fuse.js';
import companyCard from '@/components/company/CompanyCard.vue';
import card from '@/components/cross/Card.vue';

const fuseOptions = {
  findAllMatches: true,
  shouldSort: true,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['name', 'address', 'city', 'country'],
};

export default {
  name: 'ChangeCompany',
  metaInfo: {
    title: 'Select company',
  },
  components: {
    'company-card': companyCard,
    card,
  },
  data() {
    return {
      searchInput: '',
      filteredCompanies: null,
      fuse: null,
    };
  },
  computed: {
    ...mapGetters('companies', { findCompanyInStore: 'find' }),
    ...mapGetters('memberships', { findMembershipsInStore: 'find' }),
    companiesFromStore() {
      const memberships = this.findMembershipsInStore({
        query: {
          userId: this.$store.getters['auth/user'].id,
        },
      });
      const companyMemberships = memberships && memberships.data ? memberships.data : [];

      if (companyMemberships.length < 1) {
        return [];
      }
      const companies = this.findCompanyInStore({
        query: {
          id: { $in: companyMemberships.map((x) => x.companyId) },
        },
      });
      return companies && companies.data
        ? companies.data.sort((a, b) => a.name.localeCompare(b.name)) : [];
    },
  },
  watch: {
    companiesFromStore() {
      this.updateUIData();
    },
  },
  created() {
    this.updateUIData(); // Load companies from Local Storage
    this.clearCurrentSelections();
    this.fetchCompaniesFromApi();
  },
  methods: {
    ...mapActions('companies', { findCompanies: 'find' }),
    ...mapActions('memberships', { findMemberships: 'find' }),
    ...mapMutations(['clearCurrentSelections']),
    search(inputParam) {
      let input = inputParam;
      this.filteredActions = [];
      if (input) {
        input = input.trim();
      } else {
        input = this.searchInput.trim();
      }
      if (input === '') {
        this.filteredCompanies = this.companiesFromStore;
      } else {
        this.filteredCompanies = this.fuse ? this.fuse.search(input) : this.companiesFromStore;
      }
    },
    updateUIData() {
      this.fuse = new Fuse(this.companiesFromStore, fuseOptions);
      this.search();
    },
    async fetchCompaniesFromApi(skip) {
      let skipMemberships = skip;
      if (!skipMemberships) skipMemberships = 0;
      const { total, data } = await this.findMemberships({
        query: {
          $sort: { createdAt: -1 },
          $skip: skipMemberships,
          $limit: 50,
          userId: this.$store.getters['auth/user'].id,
        },
      });

      this.findCompanies({
        query: {
          id: { $in: data.map((x) => x.companyId) },
          $limit: 50,
        },
      });

      skipMemberships += data.length;
      if (skipMemberships < total) {
        await this.fetchMembershipFromApi(skipMemberships);
      }
    },
  },
};
</script>
<style scoped>
.material-card {
  margin: 0 auto;
}

.company-card {
  margin: 10px 0;
}

</style>
