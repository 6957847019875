<template>
  <div
    v-if="company"
    class="material-card company-card"
  >
    <router-link
      :to="{ name: 'devices', params: { companyId: company.id } }"
    >
      <div
        class="company-card-content card-default-color"
        @click="addLog({companyId: company.id, name: 'company.name'})"
      >
        <article
          class="media"
          style="align-items: center;"
        >
          <figure class="media-left">
            <p class="image is-64x64 center-image">
              <CompanyLogo
                class="company-logo"
                :url-logo="company.urlLogo"
                :company-name="company.name"
                style="max-width:40px;"
              />
            </p>
          </figure>
          <div class="media-content">
            <div class="content">
              <div>
                <strong>{{ company.name }}</strong>
                <div
                  v-if="company.address && company.city && company.country"
                  @click="addLog({companyId: company.id, name: company.name})"
                >
                  {{ company.address }}, {{ company.city }}, {{ company.country }}
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </router-link>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CompanyLogo from '@/components/cross/CompanyLogo.vue';

export default {
  name: 'CompanyCard',
  components: {
    CompanyLogo,
  },
  props: {
    company: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    $route() {
      this.addLog();
    },
  },
  methods: {
    ...mapActions('admin-log', { findAdminLog: 'find', createAdminLog: 'create' }),

    async addLog(action) {
      const user = await this.$store.getters['auth/user'];
      const log = {
        user: user.email,
        action: 'Acceded to company.',
        companyId: action.companyId,
      };
      await this.createAdminLog(log);
    },
  },
};
</script>

<style scoped>
.company-card {
  margin: 10px 0px;
}
.card-default-color {
  color: black;
}
.company-card-content {
  display: flex;
  padding: 12px;
}
.center-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.company-logo{
  width:100%;
  max-width:35px;
  margin-right:10px;
}
</style>
